<template>
  <div>
    <s-search
      :queryItems="queryItems"
      @fetchData="fetchDevices"
      :devicesList_BcCs_id="devicesList_BcCs_id"
    ></s-search>

    <div class="table-button">
      <a-button
        type="plain"
        @click="ExportTimeBTN"
        v-if="
          isShowBtns.indexOf('DeviceList-/api/device/deviceonoffexport') > -1
        "
        >导出设备累计时长</a-button
      >
      <a-button
        type="plain"
        icon="cluster"
        @click="handleDestribute('destribute', '设备分配')"
        :disabled="selectedRowKeys.length === 0"
        v-if="isShowBtns.indexOf('DeviceList-/api/device/distribution') > -1"
        >设备分配</a-button
      >
      <a-button
        type="plain"
        icon="cloud-download"
        @click="handleExport"
        v-if="isShowBtns.indexOf('DeviceList-/api/device/deviceexport') > -1"
        >导出</a-button
      >
      <columns-set
        tableName="DeviceList"
        :columns="columns"
        @changeColumns="changeColumns"
      ></columns-set>
    </div>

    <s-table
      ref="table"
      size="default"
      rowKey="d_id"
      :rowSelection="rowSelection"
      :columns="tableColumns"
      :data="loadData"
      :showPagination="true"
      :scroll="{ x: 1200, y: 'calc(82vh - 180px )' }"
    >
      <span slot="设备启用日期" class="TitleFlex">
        <div>设备启用日期</div>
        <div class="Title_icon">
          <p
            class="Title_up"
            @click="sortColumn(1, 2, 'd_timeBlue')"
            :class="d_timeBlue == true ? 'BlueBlock' : ''"
          >
            <a-tooltip placement="right">
              <template slot="title">升序</template>
              <a-icon type="caret-up" />
            </a-tooltip>
          </p>
          <p
            class="Title_down"
            @click="sortColumn(1, 1, 'd_timeDownBlue')"
            :class="d_timeDownBlue == true ? 'BlueBlock' : ''"
          >
            <a-tooltip placement="right">
              <template slot="title">降序</template>
              <a-icon type="caret-down" />
            </a-tooltip>
          </p>
        </div>
        <!-- <div>
          <a-tooltip>
            <template slot="title">负责人所负责的工地员工报警总次数</template>
            <a-icon type="question-circle" class="ws_nameTitle_icon" />
          </a-tooltip>
        </div> -->
      </span>
      <span slot="套餐剩余天数" class="TitleFlex">
        <div>套餐剩余天数</div>
        <div class="Title_icon">
          <p
            class="Title_up"
            @click="sortColumn(2, 2, 'd_remain_dayBlue')"
            :class="d_remain_dayBlue == true ? 'BlueBlock' : ''"
          >
            <a-tooltip placement="right">
              <template slot="title">升序</template>
              <a-icon type="caret-up" />
            </a-tooltip>
          </p>
          <p
            class="Title_down"
            @click="sortColumn(2, 1, 'd_remain_dayDownBlue')"
            :class="d_remain_dayDownBlue == true ? 'BlueBlock' : ''"
          >
            <a-tooltip placement="right">
              <template slot="title">降序</template>
              <a-icon type="caret-down" />
            </a-tooltip>
          </p>
        </div>
        <!-- <div>
          <a-tooltip>
            <template slot="title">负责人所负责的工地员工报警总次数</template>
            <a-icon type="question-circle" class="ws_nameTitle_icon" />
          </a-tooltip>
        </div> -->
      </span>
      <span slot="设备分配日期" class="TitleFlex">
        <div>设备分配日期</div>
        <div class="Title_icon">
          <p
            class="Title_up"
            @click="sortColumn(3, 2, 'd_distribution_timeBlue')"
            :class="d_distribution_timeBlue == true ? 'BlueBlock' : ''"
          >
            <a-tooltip placement="right">
              <template slot="title">升序</template>
              <a-icon type="caret-up" />
            </a-tooltip>
          </p>
          <p
            class="Title_down"
            @click="sortColumn(3, 1, 'd_distribution_timeDownBlue')"
            :class="d_distribution_timeDownBlue == true ? 'BlueBlock' : ''"
          >
            <a-tooltip placement="right">
              <template slot="title">降序</template>
              <a-icon type="caret-down" />
            </a-tooltip>
          </p>
        </div>
        <!-- <div>
          <a-tooltip>
            <template slot="title">负责人所负责的工地员工报警总次数</template>
            <a-icon type="question-circle" class="ws_nameTitle_icon" />
          </a-tooltip>
        </div> -->
      </span>
      <span slot="serial" slot-scope="text, record, index">
        {{ (pageNo - 1) * pageSize + index + 1 }}
      </span>
      <span slot="ws_name" slot-scope="text">
        <a-tooltip placement="topLeft">
          <template slot="title">
            <span>{{ text }}</span>
          </template>
          <span @click="jumpToSiteList(text)" class="cursor_pointer">{{
            text.replace(/直辖市|-北京|-天津/g, "")
          }}</span>
        </a-tooltip>
      </span>
      <!-- d_bind_time -->
      <span slot="d_distribution_time" slot-scope="text">
        {{ $Format(text, "date") }}
      </span>
      <span slot="d_bind_time" slot-scope="text">
        {{ text != "null" ? $Format(text, "date") : "-" }}
      </span>
      <span slot="d_time" slot-scope="text">
        {{ $Format(text, "date") }}
      </span>
      <span slot="d_remain_day" slot-scope="text">
        {{ $calcDate(text) }}
      </span>
      <span slot="d_bindstatus" slot-scope="text">
        <span v-if="text == 2 ? true : false">
          <!-- <span style="color: green">●</span> -->
          <span>已绑定</span></span
        >
        <span v-if="text == 1 ? true : false">
          <!-- <span style="color: red">●</span> -->
          <span>未绑定</span></span
        >
      </span>
      <span slot="d_status" slot-scope="text, res">
        <span v-if="res.d_onoff === 1 ? true : false" style="color: green"
          >●</span
        >
        <span v-else style="color: red">●</span>
        <!-- <span v-if="res.d_onoff === 2 ? true : false" style="color: red"
          >●</span
        > -->
        {{ text | FDeviceStatus }}
      </span>
      <span slot="iccid" slot-scope="text">
        <a-tooltip placement="topLeft">
          <template slot="title">
            <span>{{ text }}</span>
          </template>
          <span>{{ text }}</span>
        </a-tooltip>
      </span>
      <span slot="w_name" slot-scope="text" v-if="text">
        {{ text }}
        <!-- <span v-if="text.search('（') > -1">
          {{ text.replace(/\（[^\)]*\）/g, "") }}
          <br />
          {{ text.match(/（(.+?)）/g)[0] }}
        </span>
        <span v-else>{{ text }}</span> -->
      </span>

      <span slot="action" slot-scope="text, record" class="action,">
        <a
          v-show="
            record.d_status == '1' &&
            isShowBtns.indexOf('DeviceList-/api/worksite/camera/video') > -1
          "
          @click="openDrawer('SitePreview', '直播', record)"
          class="action_a"
          >直播</a
        >
        <a
          @click="openDrawer('bindRecord', '绑定记录', record)"
          class="action_a"
          v-if="isShowBtns.indexOf('DeviceList-/api/device/bindaction') > -1"
          >绑定记录</a
        >
        <a
          v-if="isShowBtns.indexOf('DeviceList-/api/device/updatedevice') > -1"
          class="action_a"
          @click="openDrawer('DeviceUpdate', '修改', record)"
          >修改</a
        >

        <a-popconfirm
          title="确定删除此条信息吗?"
          @confirm="() => onDelete(record)"
          v-if="isShowBtns.indexOf('DeviceList-/api/device/deldevice') > -1"
        >
          <a class="delName">删除</a>
        </a-popconfirm>
        <!-- <a @click="openDrawer('deviceDetail', '详情', record)">详情</a> -->
      </span>
    </s-table>

    <a-modal
      class="tips-modal"
      title="提示"
      okText="确定"
      cancelText="取消"
      :visible="visibleExport"
      :confirm-loading="confirmExportLoading"
      @ok="exportOk"
      @cancel="exportCancel"
    >
      <p>确定导出？</p>
    </a-modal>
    <!-- 导出设备累计时长  v-model="exportOkTime"-->
    <a-modal
      class="tips-modal"
      title="导出设备累计时长"
      @ok="ExportOkClick"
      :confirm-loading="confirmExportLoadingTime"
      :visible="exportOkTime"
      @cancel="exportCancelTime"
    >
      <p>只能导出2023-10-30号之后的数据</p>
      <!-- :default-value="[
          moment('2023-04-01', dateFormat),
          moment('2023-04-05', dateFormat),
        ]" -->

      <a-range-picker
        :format="dateFormat"
        @change="onChangeTime"
        :disabled-date="disabledDate"
        @openChange="openChange"
      />
      <a-radio-group v-model="export_ype" @change="onChangeExporType">
        <a-radio value="all"> 全部 </a-radio>
        <a-radio value="binding"> 已绑定 </a-radio>
      </a-radio-group>
    </a-modal>
    <!-- width="50%" -->
    <a-drawer
      :title="title"
      placement="right"
      width="800"
      :visible="visible"
      :destroyOnClose="true"
      @close="onClose"
      class="site-modal"
    >
      <destribute
        :record="record"
        :distribution="distribution"
        @onClose="onClose"
        v-if="destributeShow"
        ref="Destribute"
      ></destribute>
      <bindRecord
        :id="id"
        :imei="imei"
        :recordBind="recordBind"
        v-if="bindRecordShow"
      ></bindRecord>
      <deviceDetail
        :id="id"
        :imei="imei"
        :recordBind="recordBind"
        v-if="deviceDetailShow"
      ></deviceDetail>
      <SitePreview
        :record="recordBind"
        v-if="SitePreviewShow"
        ref="SitePreview"
      >
      </SitePreview>
      <DeviceUpdate
        :record="recordBind"
        v-if="DeviceUpdateShow"
        @onClose="onClose"
      />
    </a-drawer>
  </div>
</template>

<script>
import { STable, SSearch, ColumnsSet } from "@/components";
import { GetDevices, Deldevice } from "@/api/device";
import { ExportDevices, Getdeviceonoffexport } from "@/api/export";
import Destribute from "./Destribute.vue";
import BindRecord from "./BindRecord.vue";
import DeviceDetail from "./DeviceDetail.vue";
import moment from "moment";
import { urlConfig } from "@/config/config";
import SitePreview from "../site/SitePreview.vue";
import DeviceUpdate from "./DeviceUpdate.vue";

const queryItems = [
  { label: "IMEI", value: "d_imei", type: "input" },
  {
    label: "设备绑定状态",
    value: "d_bindstatus",
    type: "select",
    selectOption: "SiteBindStatus",
    defaultValue: "0",
  },
  {
    label: "设备状态",
    value: "d_status",
    type: "select",
    selectOption: "DeviceStatus",
    defaultValue: "0",
  },
  {
    label: "设备所属组织",
    value: "BcCs_id",
    type: "TreeSelect",
    range: ["bc_id", "cs_id"],
    selectOption: "CompanystoreList",
    defaultValue: ["", ""],
  },
  { label: "当前绑定人", value: "w_name", type: "input", defaultValue: "" },
  { label: "项目经理", value: "d_jl_name", type: "input" },
  { label: "工程管家", value: "d_gj_name", type: "input" },
  {
    label: "设备分配日期",
    value: "d_distribution_time",
    type: "dateRange",
    range: ["d_distribution_start_time", "d_distribution_end_time"],
    defaultValue: [],
  },
  {
    label: "设备绑定日期",
    value: "d_bind_time",
    type: "dateRange",
    range: ["d_bind_start_time", "d_bind_end_time"],
    defaultValue: [],
  },
  {
    label: "设备启用日期",
    value: "d_time",
    type: "dateRange",
    range: ["d_enable_start_time", "d_enable_end_time"],
    defaultValue: [],
  },
  {
    label: "工地所属分公司",
    value: "ws_bc_id",
    type: "select",
    selectOption: "CompanyList",
  },
];

const columns = [
  {
    title: "序号",
    width: "70px",
    scopedSlots: { customRender: "serial" },
    dataIndex: "serial",
    // sortDirections: ["descend", "ascend"],
    align: "center",
  },
  {
    title: "设备IMEI号",
    width: "160px",
    dataIndex: "d_imei",
    align: "center",
  },
  {
    title: "设备所属组织",
    width: "150px",
    dataIndex: "bc_org",
    align: "center",
  },
  {
    title: "绑定状态",
    width: "90px",
    dataIndex: "d_bindstatus",
    scopedSlots: { customRender: "d_bindstatus" },
    align: "center",
  },
  {
    title: "设备状态",
    width: "90px",
    dataIndex: "d_status",
    scopedSlots: { customRender: "d_status" },
    align: "center",
  },
  {
    title: "项目经理",
    width: "90px",
    dataIndex: "d_jl_name",
    align: "center",
  },
  {
    title: "工程管家",
    width: "90px",
    // width: "75px",
    dataIndex: "d_gj_name",
    align: "center",
  },
  {
    title: "绑定工地名称",
    width: "250px",
    dataIndex: "ws_name",
    scopedSlots: { customRender: "ws_name" },
    ellipsis: true,
  },

  {
    title: "当前绑定人",
    width: "150px",
    dataIndex: "w_name",
    scopedSlots: { customRender: "w_name" },
    align: "center",
  },
  // {
  //   title: "工地所属分公司",
  //   width: "120px",
  //   // dataIndex: "w_name",
  //   // scopedSlots: { customRender: "w_name" },
  //   align: "center",
  // },
  {
    // title: "套餐剩余天数",
    width: "145px",
    dataIndex: "d_remain_day",
    scopedSlots: { customRender: "d_remain_day" },
    slots: { title: "套餐剩余天数" },
    align: "center",
  },
  {
    // title: "设备分配日期",
    width: "145px",
    dataIndex: "d_distribution_time",
    scopedSlots: { customRender: "d_distribution_time" },
    slots: { title: "设备分配日期" },
    align: "center",
  },
  {
    title: "设备绑定日期",
    width: "120px",
    dataIndex: "d_bind_time",
    scopedSlots: { customRender: "d_bind_time" },
    align: "center",
  },
  {
    // title: "设备启用日期",
    width: "145px",
    dataIndex: "d_time",
    scopedSlots: { customRender: "d_time" },
    slots: { title: "设备启用日期" },
    align: "center",
  },

  {
    title: "设备账号",
    width: "130px",
    dataIndex: "d_htx_id",
    align: "center",
  },
  // {
  //   title: "设备ICCID",
  //   width: "120px",
  //   dataIndex: "iccid",
  //   scopedSlots: { customRender: "iccid" },
  //   align: "center",
  //   ellipsis: true,
  // },
  {
    title: "操作",
    width: "200px",
    scopedSlots: { customRender: "action" },
    fixed: "right",
    dataIndex: "action",
    align: "center",
  },
];
const orderParam = [
  "bc_id",
  "w_name",
  "d_status",
  "d_imei",
  "d_gj_name",
  "d_jl_name",
  "d_enable_start_time",
  "d_enable_end_time",
  "d_distribution_start_time",
  "d_distribution_end_time",
  "d_bind_start_time",
  "d_bind_end_time",
  "d_bindstatus",
  "cs_id",
  "ws_bc_id",
  "sort_column",
  "sort_rule",
  "pageNo",
  "pageSize",
];

export default {
  name: "DeviceList",
  components: {
    STable,
    SSearch,
    Destribute,
    BindRecord,
    DeviceDetail,
    ColumnsSet,
    SitePreview,
    DeviceUpdate,
  },
  data() {
    this.queryItems = queryItems;
    this.columns = columns;
    return {
      tableColumns: [],
      visible: false,
      visibleExport: false,
      confirmExportLoading: false,
      title: "",
      destributeShow: false,
      bindRecordShow: false,
      deviceDetailShow: false,
      SitePreviewShow: false,
      DeviceUpdateShow: false,
      id: "",
      // 查询参数
      queryParam: {
        d_status: "0", //设备状态
        d_bindstatus: "0",
        bc_id: "",
        w_name: "",
        d_gj_name: "",
        d_jl_name: "",
        cs_id: "",
        sort_column: "", //
        sort_rule: "", //升序降序
      },
      selectedRowKeys: [],
      selectedRows: [],
      record: {},
      destribute: {
        w_id: "",
      },
      imei: "",
      recordBind: "",
      distribution: "2",
      colorline: "colorline1",
      //当前页数，当前条数
      pageNo: 1,
      pageSize: 10,
      exportOkTime: false,
      dateFormat: "YYYY-MM-DD",
      monthFormat: "YYYY-MM",
      dateFormatList: ["DD-MM-YYYY", "DD-MM-YY"],
      ParamsExport: {},
      pathUrl: urlConfig.baseUrl,
      confirmExportLoadingTime: false,
      visibleExportTime: false,
      isFist: "YES",
      devicesList_BcCs_id: [],
      d_timeBlue: false,
      d_timeDownBlue: true,
      d_remain_dayBlue: false,
      d_remain_dayDownBlue: false,
      d_distribution_timeBlue: false,
      d_distribution_timeDownBlue: false,
      export_ype: "binding",
      isShowBtns: [],
    };
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
      };
    },
  },
  created() {
    this.tableColumns = columns;
    this.changeColumns();
    // 管理端看板跳转传值
    if (this.$route.path) {
      // console.log(this.$route.query);
      // 默认值的改变
      for (let item in this.$route.query) {
        // 默认值的改变
        this.queryItems.forEach((el) => {
          if (el.value == item) {
            el.defaultValue = this.$route.query[item];
          }
        });
        // 查询值的改变
        for (let index in this.queryParam) {
          if (index == item) {
            this.queryParam[index] = this.$route.query[item];
            // console.log(index, item);
          }
          // console.log(this.queryParam[index]);
        }
      }
      this.devicesList_BcCs_id = this.$route.query.BcCs_id;
      this.queryItems[3].defaultValue = [
        this.$route.query.bc_id,
        this.$route.query.cs_id,
      ];
    }
  },
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtns").split(",");
  },
  methods: {
    moment,
    disabledDate(current) {
      // Can not select days before today and today
      // return current && current >= moment().endOf("day");
      // - 8.64e7 表示可选择当天日期， 1698629497000
      return (
        current <= moment("2023-10-30").startOf("day") ||
        current > Date.now() - 8.64e7
      );
    },
    // 打开面板时，调用方法，显示前一月和当前月的日期
    openChange(status) {
      // console.log(status); //true  false
      if (this.isFist == "YES" && status) {
        setTimeout(() => {
          const pre = document.getElementsByClassName(
            "ant-calendar-prev-month-btn"
          );
          pre[0].click();
        });
        setTimeout(() => {
          const next = document.getElementsByClassName(
            "ant-calendar-prev-month-btn"
          );
          if (next[1]) {
            next[1].click();
          }
        }, 10);
      }
      if (!status) {
        this.isFist = "NO";
      }
    },
    // 自定义表头列
    changeColumns() {
      let ColumnsSet = JSON.parse(localStorage.getItem("DeviceListColumnsSet"));
      let value = ColumnsSet ? ColumnsSet : [];
      let selectColumns = columns.filter((el) => value.includes(el.dataIndex));
      this.tableColumns = selectColumns.length ? selectColumns : columns;
    },
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      // console.log(parameter);
      this.pageNo = parameter.pageNo;
      this.pageSize = parameter.pageSize;
      const param = Object.assign({}, parameter, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        ws_status: "",
        // d_status: "",
        ws_bind_status: "",
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetDevices(params).then((res) => {
        res.data.pageNo = res.data.page;
        res.data.totalCount = res.data.sum;
        return res.data;
      });
    },
    fetchDevices(value) {
      this.queryParam = value;
      this.$refs.table.refresh();
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // 设备分配
    handleDestribute() {
      this.record = this.selectedRows;
      this.visible = true;
      this.SitePreviewShow = false;
      this.bindRecordShow = false;
      this.deviceDetailShow = false;
      this.DeviceUpdateShow = false;
      this.destributeShow = true;
    },
    onClose(refresh) {
      if (refresh) {
        // this.fetchDevices();
      }
      this.visible = false;
    },
    // 导出
    handleExport() {
      this.visibleExport = true;
    },
    exportOk() {
      this.confirmExportLoading = true;
      const params = {
        bc_name: this.queryParam.bc_name,
        w_name: this.queryParam.w_name,
        d_gj_name: this.queryParam.d_gj_name,
        d_status: this.queryParam.d_status,
        d_imei: this.queryParam.d_imei,
        d_bindstatus: this.queryParam.d_bindstatus,
        d_time: this.queryParam.d_time,
        d_distribution_time: this.queryParam.d_distribution_time,
        d_bind_time: this.queryParam.d_bind_time,
        d_jl_name: this.queryParam.d_jl_name,
        d_gj_name: this.queryParam.d_gj_name,
        cs_id: this.queryParam.cs_id,
        sort_column: this.queryParam.sort_column, //
        sort_rule: this.queryParam.sort_rule, //升序降序
        ws_bc_id: this.queryParam.ws_bc_id,
      };
      ExportDevices(params).then((res) => {
        var blob = res;
        var reader = new FileReader();
        reader.readAsDataURL(blob); // 转换为base64，可以直接放入a标签href
        reader.onload = function (e) {
          // 转换完成，创建一个a标签用于下载
          var a = document.createElement("a");
          a.download = "设备列表.xls";
          a.href = e.target.result;
          // $("body").append(a);    // 修复firefox中无法触发click
          a.click();
          a.remove();
        };

        this.visibleExport = false;
        this.confirmExportLoading = false;
      });
    },

    exportCancel() {
      this.visibleExport = false;
    },
    exportCancelTime() {
      this.exportOkTime = false;
    },
    // 绑定记录 详情
    openDrawer(temp, title, record) {
      this.visible = true;
      this.imei = record.d_imei;
      this.deviceDetailShow = false;
      this.bindRecordShow = false;
      this.SitePreviewShow = false;
      this.destributeShow = false;
      this.DeviceUpdateShow = false;
      this[temp + "Show"] = true;
      this.title = title;
      if (temp === "destribute") {
        this.record = this.selectedRows;
      } else {
        this.id = record.d_id;
      }
      this.recordBind = record;
      this.recordBind.DeviceList = "DeviceList";
    },
    // 导出设备累计时长
    ExportTimeBTN() {
      this.exportOkTime = true;
    },
    ExportOkClick() {
      // let obj={};
      console.log(JSON.stringify(this.ParamsExport) === "{}");
      let obj = JSON.stringify(this.ParamsExport) === "{}";
      //返回true
      if (obj == false && this.ParamsExport.time_start != "NaN") {
        this.ParamsExport.export_ype = this.export_ype;
        this.GetDeviceonoffexport(this.ParamsExport);
      } else {
        this.$message.warning("请选择日期");
      }
    },
    onChangeTime(date, dateString) {
      var dateStart = Date.parse(dateString[0]) / 1000;
      var dateEnd = Date.parse(dateString[1]) / 1000;
      let params = {
        time_start: dateStart + "",
        time_end: dateEnd + "",
        export_ype: this.export_ype,
      };

      this.ParamsExport = params;
    },
    onChangeExporType(e) {
      // console.log(e.target.value);
      // console.log(this.export_ype);
    },
    GetDeviceonoffexport(params) {
      this.confirmExportLoadingTime = true;
      Getdeviceonoffexport(params).then((res) => {
        var blob = res;
        var reader = new FileReader();
        reader.readAsDataURL(blob); // 转换为base64，可以直接放入a标签href
        reader.onload = function (e) {
          // 转换完成，创建一个a标签用于下载
          var a = document.createElement("a");
          a.download = "设备累计时长列表.xls";
          a.href = e.target.result;
          // $("body").append(a);    // 修复firefox中无法触发click
          a.click();
          a.remove();
        };
        this.exportOkTime = false;
        this.confirmExportLoadingTime = false;
      });
    },
    // 点击工地地址跳转到工地列表页面
    jumpToSiteList(text) {
      localStorage.setItem("path", "SiteList");
      this.$router.push({
        path: "/SiteList",
        query: {
          ws_name: text,
        },
      });
    },
    // 排序
    sortColumn(sort_column, sort_rule, type) {
      console.log(sort_column, sort_rule, type);
      this.queryParam.sort_column = sort_column;
      this.queryParam.sort_rule = sort_rule;
      // this.loadData();
      this.$refs.table.refresh(true);

      if (type) {
        // this[type+'Blue']=true
        this.d_timeBlue = false;
        this.d_timeDownBlue = false;
        this.d_remain_dayBlue = false;
        this.d_remain_dayDownBlue = false;
        this.d_distribution_timeBlue = false;
        this.d_distribution_timeDownBlue = false;
        this[type] = true;
        console.log(type);
      }
    },
    // 删除设备信息
    onDelete(record) {
      console.log("删除", record);
      const param = {
        d_id: record.w_id,
      };
      const orderParam = ["d_id"];
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return Deldevice(params).then((res) => {
        console.log(res);
      });
    },
  },
};
</script>
<style lang="less" scoped>
// 表头居中显示
/deep/.ant-table-thead > tr > th {
  text-align: center;
}
.action_a {
  margin-right: 10px;
}
.cursor_pointer {
  cursor: pointer;
}
.cursor_pointer:hover {
  color: #1890ff;
}
.TitleFlex {
  display: flex;
  // line-height: 100%;
  align-items: center;
  .Title_icon {
    // margin-right: 10px;
    margin-left: 10px;
    // margin-top: -10px;
    padding-top: 10px;
    color: gray;
  }
  .Title_up {
    // margin-bottom: -15px;
    margin-bottom: 0%;
    // background-color: pink;
    width: 15px;
    height: 10px;
    line-height: 10px;
  }
  .Title_down {
    // margin-top: -15px;
    width: 15px;
    height: 10px;
    line-height: 10px;
    // background-color: blue;
    // :hover {
    //   // color: aqua;
    // }
  }
  .BlueBlock {
    color: rgb(3, 104, 255);
  }
}
.delName {
  color: red;
}
</style>

<template>
  <div class="destri-cont">
    <div>设备IMEI号</div>
    <!-- <div style="display: inline-block;height: 32px;margin: 8px 0 12px 0">
      <a-input v-model="imeiNum" style="width: 350px;" placeholder="请添加设备IMEI号"></a-input>
      <a-button type="plain" @click="addTags()">添加</a-button>
    </div> -->
    <div style="height: 240px; background: #fafafa">
      <a-tag
        v-for="(item, index) in imeiList"
        :key="index"
        closable
        @close="preventDefault(index)"
        class="imei-tags"
      >
        {{ item }}
      </a-tag>
    </div>

    <a-row style="margin-top: 40px">
      <a-col :span="8">
        <div>设备所属分公司</div>
        <!-- <a-select
          v-model="desParam.company"
          @change="companyChange"
          placeholder="请选择分公司名称"
          class="search-expand-value"
        >
          <a-select-option
            v-for="item in companyList"
            :key="item.bc_id"
            :value="item.bc_id"
            >{{ item.bc_name }}</a-select-option
          >
        </a-select> -->
        <!--  show-search -->
        <a-tree-select
          v-model="desParam.company"
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          placeholder="Please select"
          allow-clear
          tree-default-expand-all
          @change="TreeSelectChange"
          labelInValue
        >
          <a-tree-select-node
            v-for="item in companyList"
            :key="item.bc_id"
            :value="item.bc_id"
            :title="item.bc_name"
          >
            <a-tree-select-node
              v-for="el in item.cs_data"
              :key="el.cs_id"
              :value="el.cs_id"
              :title="el.cs_name"
            >
            </a-tree-select-node>
          </a-tree-select-node>
        </a-tree-select>
      </a-col>
      <!-- <a-col :span="8" style="margin-left: 24px;">
        <label>项目经理</label>
        <a-select
          show-search
          placeholder="请选择项目经理"
          option-filter-prop="children"
          style="width: 200px"
          @change="handleChange"
        >
          <a-select-option
            v-for="m in managers"
            :key="m.w_id"
            :value="m.w_id"
            >{{ m.w_name }}</a-select-option
          >
        </a-select>
      </a-col> -->
    </a-row>
    <div class="dest-bottom" style="margin-top: 20px">
      <a-button
        icon="cloud-download"
        :loading="exportDistributeLoading"
        @click="exportDistributeOK()"
      >
        导出
      </a-button>
      <a-button @click="destributeCancel()"> 取消 </a-button>
      <a-button
        type="primary"
        :loading="confirmLoading"
        @click="destributeOk()"
      >
        确认
      </a-button>
    </div>
  </div>
</template>
<script>
import { ExportDistribute } from "@/api/export";
import {
  DistributeDevice,
  GetManager,
  GetCompanies,
  GetCompaniesStore,
} from "@/api/device";

export default {
  name: "Destribute",
  props: {
    record: {
      type: Array,
      default: [],
    },
    distribution: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      imeiNum: "",
      imeiList: [],
      didList: [],
      exportDistributeLoading: false,
      destribute: {},
      confirmLoading: false,
      managers: [],
      desParam: {
        company: "",
      },
      companyList: [],
      treeSelectValue: {},
      valueList: [],
      valueListChild: [],
      bc_idValue: "",
      cs_idValue: "",
    };
  },
  created() {
    this.init();
    this.fetchCompanies();
    // console.log(this.distribution);
  },
  watch: {
    companyList(Nvalue) {
      // this.companyList = Nvalue;
      // console.log(Nvalue);
    },
  },
  methods: {
    init() {
      // 初始化imei号
      this.imeiList = [];
      this.didList = [];
      this.record.forEach((el) => {
        this.imeiList.push(el.d_imei);
        this.didList.push(el.d_id);
      });
    },
    addTags() {
      if (this.imeiList.indexOf(this.imeiNum) === -1) {
        this.imeiList.push(this.imeiNum);
      }
    },
    // 关闭标签
    preventDefault(index) {
      this.imeiList.splice(index, 1);
      this.didList.splice(index, 1);
    },
    // 查询分公司
    fetchCompanies() {
      // GetCompanies().then((res) => {
      //   this.companyList = res.data;
      //   // console.log(this.companyList);
      // });
      let order = ["bc_id", "bc_name", "cs_data"];
      const param = {
        // d_ids: this.imeiList.toString(),
        // d_ids: this.didList.toString(),
        // w_id: this.destribute.w_id,
        // bc_id: this.desParam.company,
        // distribution_type: this.distribution,

        bc_id: this.desParam.company,
        bc_name: this.destribute.w_id,
        cs_data: [],
      };
      const params = {
        ...param,
        verify: this.$verify(param, order),
      };
      GetCompaniesStore(params)
        .then((res) => {
          this.companyList = res.data;

          // console.log(this.companyList);
        })
        .then(() => {
          // 构造新数组，存放父级，用于判断下拉框选择的数据是父级还是子集
          this.valueList = [];
          // this.valueListChild = [];
          this.companyList.forEach((item) => {
            this.valueList.push({ label: item.bc_name, value: item.bc_id });
            // item.cs_data.forEach((el) => {
            //   this.valueListChild.push({ label: el.cs_name, value: el.cs_id });
            // });
          });

          console.log(this.valueList);
        });
    },
    companyChange(value) {
      console.log(value);
      this.fetchManager();
    },
    // 查询项目经理
    fetchManager() {
      let params = {
        bc_id: this.desParam.company,
      };
      GetManager(params).then((res) => {
        this.managers = res.data;
      });
    },
    handleChange(value) {
      this.destribute.w_id = value;
    },
    // 设备分配
    destributeOk() {
      let order = ["d_ids", "w_id", "bc_id", "distribution_type", "cs_id"];
      const param = {
        // d_ids: this.imeiList.toString(),
        d_ids: this.didList.toString(),
        w_id: this.destribute.w_id,
        // bc_id: this.desParam.company,
        distribution_type: this.distribution,
        bc_id: this.bc_idValue,
        cs_id: this.cs_idValue,
      };
      const params = {
        ...param,
        verify: this.$verify(param, order),
      };
      this.confirmLoading = true;
      DistributeDevice(params).then((res) => {
        this.$message.success(res.data);
        this.confirmLoading = false;
        // 刷新表格
        this.imeiList = [];
        this.$emit("onClose", "fresh");
      });
    },
    // 导出
    exportDistributeOK() {
      this.exportDistributeLoading = true;
      const params = {
        d_ids: this.didList.toString(),
        w_id: this.destribute.w_id,
      };
      ExportDistribute(params).then((res) => {
        var blob = res;
        var reader = new FileReader();
        reader.readAsDataURL(blob); // 转换为base64，可以直接放入a标签href
        reader.onload = function (e) {
          // 转换完成，创建一个a标签用于下载
          var a = document.createElement("a");
          a.download = "设备分配列表.xls";
          a.href = e.target.result;
          // $("body").append(a);    // 修复firefox中无法触发click
          a.click();
          a.remove();
        };

        this.exportDistributeLoading = false;
      });
    },
    // 取消
    destributeCancel() {
      this.imeiList = [];
      this.$emit("onClose");
    },
    TreeSelectChange(value, label, extra) {
      // console.log(value, label, extra);
      // console.log(extra.triggerNode.$parent.title);
      // console.log(extra.triggerNode.$parent.value);
      // // console.log("子节点长度", extra.triggerNode.$children.length);
      // this.treeSelectValue = value;

      // 判断拿到的值属于子集还是父级
      if (JSON.stringify(this.valueList).includes(JSON.stringify(value))) {
        console.log(111);
        // 属于父级
        this.bc_idValue = value.value;
        this.cs_idValue = "";
      }
      // if (
      //   JSON.stringify(this.companyList.cs_data).includes(JSON.stringify(value))
      // )
      else {
        console.log(222);
        let cs_id = value.value.split("-");
        this.cs_idValue = cs_id[1];
        this.bc_idValue = extra.triggerNode.$parent.value;
      }
      // console.log("this.bc_idValue ", this.bc_idValue);
      // console.log(" this.cs_idValue", this.cs_idValue);
    },
  },
};
</script>
<style lang="less" scoped>
.destri-cont {
  padding: 16px;
  background-color: #fff;
}
.dest-bottom {
  text-align: right;
  button {
    margin-left: 16px;
  }
}
.imei-tags {
  margin: 10px;
}
</style>

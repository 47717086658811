<template>
  <div class="modal-content">
    <a-form :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item
        v-for="item in formOption"
        :key="item.value"
        :label="item.label"
      >
        <!-- 输入 -->
        <a-input
          v-if="item.type === 'input'"
          v-model="form[item.value]"
          :placeholder="'请输入' + item.label"
          :disabled="item.disabled"
        ></a-input>
        <!-- 搜索 -->
        <a-select
          v-if="item.type === 'select'"
          v-model="form[item.value]"
          @change="handleChange"
          :placeholder="'请选择' + item.label"
        >
          <a-select-option
            v-for="opt in item.options"
            :key="opt.value"
            :value="opt.value"
            >{{ opt.label }}</a-select-option
          >
        </a-select>
        <!-- 远程搜索  -->
        <a-select
          v-if="item.type === 'remoteSearch'"
          show-search
          v-model="form[item.value]"
          :placeholder="'请输入查询' + item.label"
          :default-active-first-option="false"
          :show-arrow="true"
          :filter-option="false"
          :not-found-content="null"
          @search="handleSearch($event, item.method, item.value)"
          @change="handleChange"
        >
          <a-select-option
            v-for="opt in item.options"
            :key="opt.value"
            :value="opt.value"
          >
            {{ opt.label }}
          </a-select-option>
        </a-select>
        <!-- 日期 v-model="form[item.value]"-->
        <a-date-picker
          v-if="item.type === 'datePicker'"
          v-model="form[item.value]"
          type="date"
          style="width: 100%"
          :format="dateFormat"
        />
      </a-form-item>
    </a-form>
    <div style="margin-top: 20px; text-align: right">
      <a-button @click="cancel()">返回</a-button>
      <a-button @click="submit()" type="primary" style="margin-left: 16px"
        >确认</a-button
      >
    </div>
  </div>
</template>
<script>
import { SelectOptions } from "@/config/SelectOptions.js";

import { GetCompanies } from "@/api/device";
import { Updatedevice } from "@/api/device";
import moment from "moment";
const formOption = [
  {
    label: "设备id",
    value: "d_id",
    type: "input",
    disabled: true,
  },
  {
    label: "设备状态",
    value: "d_status",
    type: "select",
    options: SelectOptions.DeviceStatus,
  },
  {
    label: "设备htx号",
    value: "d_htx_id",
    type: "input",
    // options: SelectOptions.WorkerSex,
  },
  {
    label: "套餐到期时间",
    value: "d_remain_day",
    type: "datePicker",
    // options: SelectOptions.WorkPost,
  },
  {
    label: "设备箱号",
    value: "d_case",
    type: "input",
    // options: SelectOptions.WorkType,
  },
  {
    label: "工地默认设备",
    value: "d_default",
    // type: "remoteSearch",
    type: "input",
    // options: [],
    // method: "GetCompanies",
  },
  {
    label: "设备名称",
    value: "d_name",
    type: "input",
  },
  {
    label: "设备版本号",
    value: "d_version",
    type: "input",
  },
  {
    label: "设备型号",
    value: "d_model",
    type: "input",
  },
];
export default {
  name: "DeviceUpdate",
  props: {
    record: {
      type: Object,
    },
  },
  data() {
    return {
      formOption: [],
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {},
      dateFormat: "YYYY-MM-DD",
      editVerify: [
        "d_id",
        "d_status",
        "d_htx_id",
        "d_remain_day",
        "d_case",
        "d_default",
        "d_name",
        "d_version",
        "d_model",
      ],
    };
  },
  async created() {
    this.formOption = formOption;
    // console.log(this.formOption);
    // console.log("edit", SelectOptions, JSON.parse(JSON.stringify(this.record)));
    this.form = JSON.parse(JSON.stringify(this.record));
    this.fetchCompany();
  },
  methods: {
    moment,
    fetchCompany() {
      let that = this;
      let DeviceStatus = SelectOptions.DeviceStatus;
      DeviceStatus.forEach((el) => {
        if (el.value == that.form.d_status) {
          that.form.d_status = el.label;
        }
      });

      let time = Date.parse(new Date()) / 1000 + that.form.d_remain_day;
      //   console.log(this.$Format(time));
      that.form.d_remain_day = moment(time * 1000);
      //   console.log(
      //     "1111",
      //     Date.parse(new Date()),
      //     that.form.d_remain_day,
      //     time,
      //     that.form.d_remain_day
      //   );
    },
    handleChange(el) {},
    handleSearch(val, method, key) {},
    cancel() {
      this.$emit("onClose");
    },
    submit() {
      let forms = JSON.parse(JSON.stringify(this.form));
      let DeviceStatus = SelectOptions.DeviceStatus;
      DeviceStatus.forEach((el) => {
        if (el.label == forms.d_status) {
          forms.d_status = el.value;
          //   console.log("111111111", forms);
        } else {
        }
      });
      console.log("222", forms);

      //   forms.d_remain_day = forms.d_remain_day.startOf("day").format("x") / 1000;
      forms.d_remain_day = Date.parse(forms.d_remain_day) / 1000;

      //   console.log(
      //     forms.d_remain_day,
      //     this.$Format(this.form.d_remain_day / 1000)
      //   );

      const orderParam = this.$order(forms, this.editVerify);
      const params = {
        ...orderParam,
        verify: this.$verify(orderParam, this.editVerify),
      };
      //   console.log("upd-params", params);
      //   console.log(this.form);
      return Updatedevice(params).then((res) => {
        this.$message.success(res.data);
        this.$emit("emitEdit");
      });
    },
  },
};
</script>

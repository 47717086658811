<template>
  <div>
    <div>
      <!-- <div>工地名称</div>
      <div>
        <a-icon type="environment" theme="twoTone" class="model-site-name" />{{
          this.recordBind.ws_name
        }}
      </div> -->
    </div>
    <s-table
      ref="table"
      size="default"
      rowKey="d_id"
      :columns="columns"
      :data="loadData"
      :showPagination="true"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ (pageNo - 1) * pageSize + index + 1 }}
      </span>
      <span slot="ws_name" slot-scope="text">
        <a-tooltip placement="topLeft">
          <template slot="title">
            <span>{{ text }}</span>
          </template>
          <span>{{
            text.length > 7 ? text.substring(0, 7) + "..." : text
          }}</span>
        </a-tooltip>
      </span>
      <span slot="d_distribution_time" slot-scope="text">
        {{ $Format(text, "date") }}
      </span>
      <span slot="d_time" slot-scope="text">
        {{ $Format(text, "date") }}
      </span>
      <span slot="d_remain_day" slot-scope="text">
        {{ $calcDate(text) }}
      </span>
    </s-table>
    <div></div>
  </div>
</template>
<script>
import { STable } from "@/components";
import { GetbindAction } from "@/api/device";
const columns = [
  {
    title: "序号",
    width: "70px",
    scopedSlots: { customRender: "serial" },
    dataIndex: "serial",
    align: "center",
  },
  {
    title: "工地名称",
    dataIndex: "ws_name",
    scopedSlots: { customRender: "ws_name" },
  },
  {
    title: "设备绑定人",
    dataIndex: "d_bind_name",
    width: "110px",
    scopedSlots: { customRender: "d_bind_name" },
    // ellipsis: true,
    align: "center",
  },
  {
    title: "设备绑定时间",
    dataIndex: "d_bind_time",
    scopedSlots: { customRender: "d_bind_time" },
    align: "center",
  },
  {
    title: "设备解绑人",
    dataIndex: "d_unbind_name",
    width: "110px",
    scopedSlots: { customRender: "d_unbind_name" },
    // ellipsis: true,
    align: "center",
  },
  {
    title: "设备解绑时间",
    dataIndex: "d_unbind_time",
    scopedSlots: { customRender: "d_unbind_time" },
    align: "center",
  },
  {
    title: "服务时间",
    dataIndex: "action_time",
    align: "center",
  },
];

const orderParam = ["d_imei", "pageNo", "pageSize"];
export default {
  name: "BindRecord",
  props: {
    imei: {
      type: String,
      default: "",
    },
    recordBind: {
      default: {},
    },
  },
  components: {
    STable,
  },
  data() {
    this.columns = columns;
    return {
      tableColumns: [],
      queryParam: {},
      //当前页数，当前条数
      pageNo: 1,
      pageSize: 10,
      d_imei: "",
    };
  },
  created() {
    // console.log(this.recordBind);
  },
  mounted() {},
  methods: {
    loadData(parameter) {
      this.pageNo = parameter.pageNo;
      this.pageSize = parameter.pageSize;
      parameter.d_imei = this.imei;
      const param = Object.assign({}, parameter, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetbindAction(params).then((res) => {
        res.data.pageNo = res.data.page;
        res.data.totalCount = res.data.sum;

        return res.data;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.model-site-name {
  font-size: 15px;
}
</style>

<template>
  <div>
    <div class="detail_top">
      <span>设备IMEI号：</span><span>绑定状态：</span> <span>设备状态：</span>
    </div>
    <!-- <div>
      <div>工地名称</div>
      <div class="adressName">
        <a-icon type="environment" theme="twoTone" class="model-site-name" />{{
          this.recordBind.ws_name
        }}
      </div>
    </div> -->
    <a-tabs
      default-active-key="1"
      @change="callback"
      type="card"
      :tabBarStyle="{ borderBottom: 'unset' }"
    >
      <a-tab-pane key="1" tab="设备基本信息" class="jibenxixin">
        <div class="xinxiName">设备信息</div>
        <div>
          <div class="flex">
            <div class="flex">
              <div class="flex_left">设备IMEI号</div>
              <div class="flex_right"></div>
            </div>
            <div class="flex">
              <div class="flex_left border_left">设备账号</div>
              <div class="flex_right"></div>
            </div>
            <div class="flex">
              <div class="flex_left border_left">设备状态</div>
              <div class="flex_right"></div>
            </div>
          </div>
        </div>
        <div>
          <div class="flex border_top">
            <div class="flex">
              <div class="flex_left border_top">绑定状态</div>
              <div class="flex_right border_top"></div>
            </div>
            <div class="flex">
              <div class="flex_left border_left border_top">设备启动日期</div>
              <div class="flex_right border_top"></div>
            </div>
            <div class="flex">
              <div class="flex_left border_left border_top">租期剩余天数</div>
              <div class="flex_right border_top"></div>
            </div>
          </div>
        </div>
        <div>
          <div class="flex">
            <div class="flex">
              <div class="flex_left border_top">目前所属分公司</div>
              <div class="flex_right border_top"></div>
            </div>
            <div class="flex">
              <div class="flex_left border_left border_top">设备型号</div>
              <div class="flex_right border_top"></div>
            </div>
            <div class="flex">
              <div class="flex_left border_left border_top">设备版本号</div>
              <div class="flex_right border_top"></div>
            </div>
          </div>
        </div>
        <div>
          <div class="flex">
            <div class="flex">
              <div class="flex_left border_top">设备ICCID</div>
              <div class="flex_right border_top"></div>
            </div>
            <div class="flex">
              <div class="flex_left border_left border_top"></div>
              <div class="flex_right border_top"></div>
            </div>
            <div class="flex">
              <div class="flex_left border_left border_top"></div>
              <div class="flex_right border_top"></div>
            </div>
          </div>
        </div>
        <div class="xinxiName Mes">设备分配记录</div>
        <!-- 表格 -->
        <a-table :columns="columns1" :data-source="data1" bordered>
          <span slot="d_bind_name" slot-scope="text, record">
            {{ text }} {{ $Format(record.d_bind_time, "date") }}
          </span>
          <span slot="d_unbind_name" slot-scope="text, record">
            {{ text }} {{ $Format(record.d_unbind_time, "date") }}
          </span>
        </a-table>
      </a-tab-pane>
      <a-tab-pane key="2" tab="设备绑定记录" class="jibenxixin">
        <div class="xinxiName">设备目前绑定信息</div>
        <div>
          <div class="flex">
            <div class="flex">
              <div class="flex_left">分公司</div>
              <div class="flex_right"></div>
            </div>
            <div class="flex">
              <div class="flex_left border_left">工地名称</div>
              <div class="flex_right"></div>
            </div>
            <div class="flex">
              <div class="flex_left border_left">工程管家</div>
              <div class="flex_right"></div>
            </div>
          </div>
        </div>
        <div>
          <div class="flex border_top">
            <div class="flex">
              <div class="flex_left border_top">项目经理</div>
              <div class="flex_right border_top"></div>
            </div>
            <div class="flex">
              <div class="flex_left border_left border_top">绑定时间</div>
              <div class="flex_right border_top"></div>
            </div>
            <div class="flex">
              <div class="flex_left border_left border_top">
                累计无人施工总天数
              </div>
              <div class="flex_right border_top"></div>
            </div>
          </div>
        </div>
        <div>
          <div class="flex">
            <div class="flex">
              <div class="flex_left border_top">工地状态</div>
              <div class="flex_right border_top"></div>
            </div>
            <div class="flex">
              <div class="flex_left border_left border_top">今日打卡状态</div>
              <div class="flex_right border_top"></div>
            </div>
            <div class="flex">
              <div class="flex_left border_left border_top">今日打卡人数</div>
              <div class="flex_right border_top"></div>
            </div>
          </div>
        </div>
        <div class="xinxiName Mes">设备绑定工地历史记录</div>
        <s-table
          ref="table"
          size="default"
          rowKey="d_id"
          :columns="columns"
          :data="loadData"
          :showPagination="true"
        >
          <span slot="serial" slot-scope="text, record, index">
            {{ (pageNo - 1) * pageSize + index + 1 }}
          </span>
          <span slot="ws_name" slot-scope="text">
            <a-tooltip placement="topLeft">
              <template slot="title">
                <span>{{ text }}</span>
              </template>
              <span>{{
                text.length > 7 ? text.substring(0, 7) + "..." : text
              }}</span>
            </a-tooltip>
          </span>
          <span slot="d_distribution_time" slot-scope="text">
            {{ $Format(text, "date") }}
          </span>
          <span slot="d_time" slot-scope="text">
            {{ $Format(text, "date") }}
          </span>
          <span slot="d_remain_day" slot-scope="text">
            {{ $calcDate(text) }}
          </span>
        </s-table>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import { STable } from "@/components";
import { GetbindAction } from "@/api/device";
const columns = [
  {
    title: "序号",
    width: "70px",
    scopedSlots: { customRender: "serial" },
    dataIndex: "serial",
    align: "center",
  },
  {
    title: "工地名称",
    dataIndex: "ws_name",
    scopedSlots: { customRender: "ws_name" },
  },
  {
    title: "设备绑定人/绑定时间",
    dataIndex: "d_bind_name",
    width: "200px",
    scopedSlots: { customRender: "d_bind_name" },
    ellipsis: true,
    align: "center",
  },

  {
    title: "设备解绑人/解绑时间",
    dataIndex: "d_unbind_name",
    width: "200px",
    scopedSlots: { customRender: "d_unbind_name" },
    ellipsis: true,
    align: "center",
  },

  {
    title: "服务时长",
    dataIndex: "action_time",
    align: "center",
  },
];
const columns1 = [
  {
    title: "序号",
    // dataIndex: "name",
    width: "10%",
    // scopedSlots: { customRender: "name" },
  },
  {
    title: "组织名称",
    // dataIndex: "name",
    width: "25%",
    // scopedSlots: { customRender: "name" },
  },
  {
    title: "分配日期",
    // dataIndex: "name",
    width: "25%",
    // scopedSlots: { customRender: "name" },
  },
  {
    title: "操作账号",
    // dataIndex: "name",
    width: "25%",
    // scopedSlots: { customRender: "name" },
  },
  {
    title: "备注",
    // dataIndex: "name",
    width: "25%",
    // scopedSlots: { customRender: "name" },
  },
];
const data1 = [];
const orderParam = ["d_imei", "pageNo", "pageSize"];
export default {
  name: "BindRecord",
  props: {
    imei: {
      type: String,
      default: "",
    },
    recordBind: {
      default: {},
    },
  },
  components: {
    STable,
  },
  data() {
    this.columns = columns;
    this.columns1 = columns1;
    return {
      tableColumns: [],
      queryParam: {},
      //当前页数，当前条数
      pageNo: 1,
      pageSize: 10,
      d_imei: "",
      data1,
      columns1,
    };
  },
  created() {
    // console.log(this.recordBind);
  },
  mounted() {},
  methods: {
    callback(key) {
      // console.log(key);
    },
    loadData(parameter) {
      this.pageNo = parameter.pageNo;
      this.pageSize = parameter.pageSize;
      parameter.d_imei = this.imei;
      const param = Object.assign({}, parameter, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetbindAction(params).then((res) => {
        res.data.pageNo = res.data.page;
        res.data.totalCount = res.data.sum;
        return res.data;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.detail_top {
  line-height: 80px;
}
div {
  background-color: #fff;
  // font-weight: 600;
  font-family: PingFang SC;
}
.model-site-name {
  font-size: 15px;
}
/deep/ .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-wrap {
  margin-left: 12px;
}
/deep/ .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  border: 1px solid #1890ff;
}
/deep/ .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  margin-right: 0;
  height: 50px;
  line-height: 30px;
  // width: 80%;
  // width: 315px;
  width: 375px;
  padding: 8px 0;
  text-align: center;
  background: none;
}
/deep/ .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
  height: 50px;
}
.adressName {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 10px;
}
.xinxiName {
  margin-left: 10px;
  margin-bottom: 10px;
  font-weight: 900;
}
.Mes {
  margin-top: 10px;
}
.flex {
  display: flex;
  // justify-content: center;

  line-height: 20px;
}
.flex_left {
  width: 110px;
  background-color: #fafafa;
  padding: 10px;
  // line-height: 40px;
  border: 1px solid #e8e8e8;
  // text-align: center;
  display: flex;
  align-items: center;
}

.flex_right {
  width: 140px;
  padding: 10px;
  border: 1px solid #e8e8e8;
  border-left: none;
  display: flex;
  align-items: center;
}
.juzhong {
  // line-height: 40px;
  display: flex;
  align-items: center;
  // background-color: pink;
}
.border_left {
  border-left: none;
}
.border_top {
  border-top: none;
}
</style>
